<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <div class="d-flex justify-end ma-3 bg-surface-variant">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="blue-grey">
          <strong class="mr-1 ml-1 mt-2 pink--text text--lighten-4"
            >เปิดครั้งละ</strong
          >
          <span class="mr-1 ml-1 mt-2">ต่ำสุด :</span>
          <v-text-field
            v-model="MinID"
            class="txtMinID"
            ref="txtMinID"
            color="white"
            @input="OnTextChange_txtMinID"
            type="number"
            outlined
            hide-details
            dense
            style="width: 55px;"
          ></v-text-field>
          <span class="mr-1 ml-1 mt-2">สูงสุด : </span>
          <v-text-field
            v-model="MaxID"
            class="txtMaxID"
            ref="txtMaxID"
            color="white"
            @input="OnTextChange_txtMaxID"
            type="number"
            outlined
            hide-details
            dense
            style="width: 55px;"
          ></v-text-field>
          <v-btn
            color="orange darken-3"
            class="ml-3 mt-0 mb-0"
            style="height: 40px;"
            @click="submitData"
            >ตกลง</v-btn
          >
        </v-row>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="id_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <Add />
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ row_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'pink darken-3': item.ID === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">
            <v-layout justify-center>{{ item.ID }}</v-layout>
          </td>
          <td :class="getColorText(item.Status)">
            <v-layout justify-center>{{ item.PW }}</v-layout>
          </td>
          <td :class="getColorText(item.Status)">
            <v-layout justify-center>{{ item.Credit }}</v-layout>
          </td>
          <td :class="getColorText(item.Status)">
            <v-layout justify-center>{{ getStatusText(item.Status) }}</v-layout>
          </td>
          <td :class="getColorText(item.Status)">
            <v-layout justify-center>{{ item.LastedActivate }}</v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Account/Popup_Menu";
import Add from "@/components/Account/Add";

export default {
  name: "Account_Datatable",

  components: {
    PopupMenu,
    Add,
  },
  created() {
    //โหลด ตาราง
    this.fetch_data();
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    id_data() {
      //console.log("gettes - - >" + this.$store.getters["id/IDList"]);
      return this.$store.getters["id/IDList"];
    },
    row_count() {
      return this.$store.getters["id/IDList"].length;
    },
  },
  methods: {
    async fetch_data() {
      let credentials = {
        web_name: sessionStorage.getItem("WebName"),
        WebName: this.WebName,
        Roomname: this.Roomname,
      };
      await this.$store.dispatch("web/get_room_detail", credentials).then(
        (response) => {
          this.MinID = response.RoomData.MinID;
          this.MaxID = response.RoomData.MaxID;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async fetch_table() {
      let credentials = {
        web_name: sessionStorage.getItem("WebName"),
      };
      await this.$store.dispatch("id/load_id_list", credentials).then(
        (response) => {
          console.log(response.IDList);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    OnTextChange_txtMinID() {
      if (parseFloat(this.MinID) < 1) {
        this.$nextTick(() => {
          this.MinID = 1;
        });
      }
    },
    OnTextChange_txtMaxID() {
      if (parseFloat(this.MaxID) < 1) {
        this.$nextTick(() => {
          this.ShootEndTime.MM = 1;
        });
      }
    },
    submitData() {
      if (this.submitted == false) this.OnChangeMinMaxID();
    },
    async OnChangeMinMaxID() {
      this.submitted = true;

      let credentials = {
        WebName: this.WebName,
        Roomname: this.Roomname,
        Method: "update_min_max",
        MinID: this.MinID,
        MaxID: this.MaxID,
      };

      await this.$store.dispatch("web/set_room_detail", credentials).then(
        (response) => {
          //   console.log(response.RoomData.Status);
          this.$alert("", "บันทึกข้อมูลเรียบร้อย", "success");
          this.submitted = false;
          // this.$emit("Next");
        },
        (error) => {
          //   this.login_message(error);
          console.log(error);
          switch (
            error
            // case "ID_DUPLICATE":
            //   this.error_text = "xxx";
            //   break;
          ) {
          }
          this.submitted = false;
        }
      );
    },
    getStatusText(p_Status) {
      return p_Status == "0" ? "ปิดใช้งาน" : "ใช้งาน";
    },
    getColorText(p_Status) {
      return p_Status == "0" ? "red--text" : "grey--text text--lighten-3";
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.ID;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.ID;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.ID);
        this.selectedId = item.ID;
        //console.log(item);
      });
    },
  },
  data: () => ({
    selectedId: -1,

    valid: true,
    submitted: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    MinID: 0,
    MaxID: 0,

    WebName: sessionStorage.getItem("WebName"),
    Roomname: sessionStorage.getItem("Roomname"),

    headers: [
      {
        text: "ID",
        value: "ID",
        align: "center",
        width: "70px",
      },
      { text: "พาส", value: "PW", align: "center", width: "80px" },
      { text: "เครดิต", value: "Credit", align: "center", width: "60px" },
      { text: "สถานะ", value: "Status", align: "center", width: "50px" },
      {
        text: "ใช้งานล่าสุด",
        value: "LastedActivate",
        align: "center",
        width: "50px",
      },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #880e4f !important;
  color: rgb(218, 207, 207);
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #880e4f !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  vertical-align: top;
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  border-right: 1px solid;
  border-top: 1px solid;
  border-color: rgb(51, 51, 51);
  /* font-size: 110% !important; */
}

::v-deep table tbody tr td {
  /* font-size: 1.5vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
  border-right: 1px solid;
  border-color: rgb(51, 51, 51);
}

::v-deep .txtMinID input[type="number"],
::v-deep .txtMinID input::-webkit-outer-spin-button,
::v-deep .txtMinID input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep .txtMaxID input[type="number"],
::v-deep .txtMaxID input::-webkit-outer-spin-button,
::v-deep .txtMaxID input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

</style>
