<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('detail')">
          <v-list-item-icon>
            <v-icon color="warning">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>รายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('edit')">
          <v-list-item-icon>
            <v-icon color="blue">mdi-lead-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>แก้ไข</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link @click="menuActionClick('delete')">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <Edit ref="edit" :edit_dialog.sync="edit_dialog"/>
  </div>
</template>

<script>
import Edit from "@/components/Account/Edit";

export default {
  name: "PopupMenu",

  components: {
    Edit,
  },

  methods: {
    Show(id) {
      this.showMenu = true;
      this.ID = id;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        // case "detail":
        //   //add session
        //   //save host_id
        //   sessionStorage.setItem("agent_id", this.id);
        //   //goto agent page
        //   this.$router.push("/Agent_Data");
        //   break;
        case "delete":
          this.$confirm("ยืนยันการลบไอดี " + this.ID + "?","","question").then(() => {
            this.do_DelID(this.ID);
          });

          break;
        case "edit":
            this.$refs.edit.set_id(this.ID);
            this.edit_dialog=true;
            break;
      }
    },
    async do_DelID(ID) {
      let credentials = {
        ID: ID,
        WebName: sessionStorage.getItem("WebName"),
      };

      await this.$store.dispatch("id/del_id", credentials).then(
        (response) => {
          this.$fire({
            title: "สำเร็จ",
            text: "ลบไอดี " + response + " เรียบร้อย",
            type: "success",
            timer: 1000,
          }).then((r) => {
            console.log(r.value);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }, //end send to server
  },
  data: () => ({
    ID: null,
    showMenu: false,
    x: 0,
    y: 0,

    edit_dialog: false,
  }),
};
</script>

<style></style>
