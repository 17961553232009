<template>
  <v-container>
    <v-app-bar fixed app color="blue-grey darken-3" dense dark>
      <v-btn icon to="/Manage">
        <v-icon large>mdi-arrow-left-thin-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>บัญชี</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>




    <Table />

  </v-container>
</template>

<script>
import Table from "@/components/Account/Table";

export default {
    name: "Account_Setting",

    components: {
        Table,
    },

};
</script>

<style></style>
