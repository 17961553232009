<template>
  <v-dialog
    v-model="edit_dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="pink darken-4">
        <v-toolbar-title>แก้ไขไอดี</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click.native="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- start form -->

      <v-card class="px-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-card-text>
            <!-- <v-card-title class="info--text">ข้อมูลในเว็บ</v-card-title> -->
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.ID"
                  :counter="12"
                  :rules="rules.id"
                  ref="txtID"
                  color="pink"
                  label="ไอดี"
                  required
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.PW"
                  :counter="12"
                  :rules="rules.pw"
                  ref="txtPW"
                  color="pink"
                  label="พาส"
                  required
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.Credit"
                  :rules="rules.credit"
                  type="number"
                  step="any"
                  ref="txtCredit"
                  color="pink"
                  label="เครดิต"
                  required
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="form.StatusItem"
                  :items="status_items"
                  item-text="n"
                  item-value="v"
                  label="Select"
                  persistent-hint
                  return-object
                  single-line
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn type="submit" color="blue darken-1" text :disabled="!valid">
              บันทึก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="prop_snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ error_text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
export default {
  name: "Edit",

  props: {
    edit_dialog: {
      default: false,
    },
  },

  methods: {
    set_id(ID) {
      this.refID = ID;
    },
    onSubmit() {
      //console.log("submit");
      //check textfeild
      if (this.form.ID.length == 0) {
        this.$refs.txtID.focus();
      } else if (this.form.PW.length == 0) {
        this.$refs.txtPW.focus();
      } else {
        //send data to server
        this.do_EditID(this.form);
      }
    },
    async do_EditID(p_credentials) {
      p_credentials.WebName = sessionStorage.getItem("WebName");
      p_credentials.Status = this.form.StatusItem.v;
      p_credentials.OLDID = this.refID;

      await this.$store.dispatch("id/edit_id", p_credentials).then(
        (response) => {
          //clear form
          //   this.$refs.form.reset();
          //   this.$refs.form.resetValidation();
          this.form.StatusItem = { n: "ใช้งาน", v: "01" };
          this.refID = response.ID;
          this.form.ID = "";
          this.form.PW = "";
          this.form.Credit = 100;
          this.$refs.form.resetValidation();
          this.$refs.txtID.focus();

          this.$alert("สำเร็จ.", "แก้ไขเรียบร้อย", "success").then(() =>
            this.close()
          );

          //   switch (response.Status) {
          //     case "0":
          //       this.$router.push("/Dashboard");
          //       break;
          //   }

          //this.$router.push("/Dashboard");
          //console.log(response);
        },
        (error) => {
          switch (error) {
            case "ID_DUPLICATE":
              this.$alert("ไอดีซ้ำ.", "ผิดพลาด", "error").then(() =>
                this.$refs.txtID.focus()
              );
              break;
          }
          //console.log("error");
        }
      );
    }, //end send to server
    async do_GetID(ID) {
      let credentials = {
        WebName: sessionStorage.getItem("WebName"),
        ID: ID,
      };

      await this.$store.dispatch("id/get_id", credentials).then(
        (response) => {
          //clear form
          // this.$refs.form.reset();
          // this.$refs.form.resetValidation();
          this.form.ID = response.ID;
          this.form.StatusItem = (response.Status=="0") ? { n: "ปิดใช้งาน", v: "00" } : { n: "ใช้งาน", v: "01" };
          this.form.PW = response.PW;
          this.form.Credit = response.Credit;
          this.$refs.form.resetValidation();
          //   this.$refs.txtID.focus();
        },
        (error) => {
          console.log(error);
        }
      );
    }, //end send to server
    close() {
      this.$emit("update:edit_dialog", false);
    },
    // show_error(error_text) {
    //   this.prop_snackbar.color = "error";
    //   this.error_text = error_text;
    //   this.snackbar = true;
    // },
  },
  data: () => ({
    notifications: false,
    sound: true,
    widgets: false,
    // form
    valid: true,

    refID: "",

    form: {
      ID: "",
      PW: "",
      Credit: 0,
      StatusItem: { n: "ใช้งาน", v: "01" },
    },

    status_items: [
      { n: "ปิดใช้งาน", v: "00" },
      { n: "ใช้งาน", v: "01" },
    ],

    rules: {
      id: [(val) => (val || "").length > 0 || "ใส่ ID ด้วย"],
      pw: [
        (v) => !!v || "ต้องใส่รหัสผ่าน",
        (v) => (v && v.length <= 25) || "รหัสผ่านใส่ไมไ่ด้เกิน 25 ตัวอักษร",
      ],
    },
    //snackbar
    snackbar: false,
    prop_snackbar: {
      color: "error",
    },
    mode: "",
    error_text: "My timeout is set to 5000.",
    timeout: 5000,
    x: null,
    y: "top",
  }),
  watch: {
    edit_dialog(val) {
      if (val) {
        setTimeout(() => {
          // read data
          this.do_GetID(this.refID);
        });
      }
    },
  },
};
</script>

<style></style>
